import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import downalodableFile from "../utilities/downloadableFile";

const initialState = {
  status: "idle",
  error: null,
};

export const exportEmployeesBalances = createAsyncThunk("export/employees-balances", async () => {
  let data;
  try {
    const response = await axios.get(`/export`,{responseType:"blob"});
    data = await response.data;
    if ((response.status === 200)) {
        downalodableFile(response.data,'employeesBalances')
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : err);
  }
});

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [exportEmployeesBalances.pending]: (state) => {
      state.status = "loading";
    },
    [exportEmployeesBalances.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [exportEmployeesBalances.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const {} = slice.actions;

export const reducer = slice.reducer;

export default slice;
